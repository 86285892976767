import { useCookies } from 'react-cookie';
import config from 'config';
import NotificationBox from 'components/NotificationBox';
import WebComponent from 'utils/web-component';
import { useEffect, useState } from 'react';

type GardePromoProps = {
  site: Site;
  inbox: number | undefined;
};

const indefinite = 400; // maximum allowed. Quota will likely be used at this point, or it might be a good time to alert again.

export default function GadrePromo({site, inbox}: GardePromoProps) {
  const [notificationShown, setNotificationShown] = useState<boolean>(false);
  const [notificationFaded, setNotificationFaded] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    if(!cookies['notification-dismissed--garde-promo']
        && inbox && hasItemsToModerate(site, inbox)
    ) {
      setNotificationShown(true);
      setNotificationFaded(false)
    } else if (!cookies['notification-dismissed--garde-promo'] && hasStartedModeration(site)){
      // has started to moderate, set cookie (again if expired)
      setPromoCookie(indefinite);
    }
  }, [cookies, site]);

  const hasItemsToModerate = (site:Site, inbox: number) => {
    return site.ai_moderation_enabled
      && site.ai_moderation_quota_left === site.ai_moderation_quota
      && inbox && inbox > 3;
  }

  const hasStartedModeration = (site:Site) => {
    return site.ai_moderation_quota_left && site.ai_moderation_quota && site.ai_moderation_quota_left < site.ai_moderation_quota;
  }

  const dismissPromo = (days: number, onceOnly?: boolean) => {
    setNotificationFaded(true)
    setTimeout(() => setNotificationShown(false), 1000);
    setPromoCookie(days, onceOnly)
  };

  const setPromoCookie = (days: number, onceOnly?: boolean) => {
    let expireTime = new Date(Date.now() + days * 24 * 60 * 60 * 1000);

    if (onceOnly && cookies['notification-dismissed--garde-promo--closed-twice']) {
      // close from X for the second time, do not show 'ever' again
      expireTime = new Date(Date.now() + indefinite * 24 * 60 * 60 * 1000);
    } else if(onceOnly) {
      // set cookie for having closed from X once
      setCookie(`notification-dismissed--garde-promo--closed-twice`, 'true', {
        expires: new Date(Date.now() + indefinite * 24 * 60 * 60 * 1000),
        path: '/',
        domain: config.flocklerBaseDomain,
      });
    }

    setCookie(`notification-dismissed--garde-promo`, 'true', {
      expires: expireTime,
      path: '/',
      domain: config.flocklerBaseDomain,
    });
  }

  return (
    <div className={`duration-[800ms] ${notificationFaded ? 'max-h-0 opacity-0 margin-0' : 'max-h-[1200px]'}`}>
      {notificationShown && <NotificationBox
        key="garde-promo"
        title={<>Meet Garde AI—Flockler’s new content moderator </>}
        severity="promo"
        icon="garde"
        dismissAction={()=>{dismissPromo(30, true)}} /* try again in a month, if not taken into use */
        actions={<>
          <WebComponent tag="fl-button" class="mt mr tight-mobile" onClick={()=>{dismissPromo(365 * 10); window.location.href = `${config.flocklerAppUrl}/${site.site_url}/sections?intro=garde`;}}>
            Try it now
          </WebComponent>
          <WebComponent tag="fl-button" variant="plain" class="mt tight-mobile" onClick={()=>{dismissPromo(7)}}>
            Remind me later
          </WebComponent>
          </>
        }
        >
        <p>Designed to save you time, filter out spammy/off-brand content, and manage your
          feeds in real-time.
        </p>
      </NotificationBox>}
    </div>
  );
};
