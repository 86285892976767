import { abbreviateNumber } from 'services/number';
import Card from '../Card';
import CardHelpDetail from '../CardHelpDetail';
import bgImage from 'images/dashboard/bg-dashboard-bars.svg';
import classNames from 'classnames';
import { pathToAutomatedFeeds } from 'services/routes';
import { Link } from 'react-router-dom';
import AngleLink from 'components/AngleLink';
import config from 'config';

interface CardPostStatsProps {
  className?: string;
  site: Site;
  posts: number;
  inbox: number;
}

const CardPostStats = ({ posts, inbox, site, className }: CardPostStatsProps) => {
  const hasData = !!posts || !!inbox;

  return (
    <Card
      detail={
        hasData && (
          <span className="absolute right-5 top-5 inline-block" title="Stats from past 7 days">
            7 days
          </span>
        )
      }
      className={classNames(className, !hasData ? 'bg-contain bg-right-bottom bg-no-repeat' : 'relative space-y-0')}
      style={{ backgroundImage: !hasData ? `url('${bgImage}')` : undefined }}
    >
      {hasData ? (
        <div className="mt-0.5 space-y-3">
          <div className="space-y-1">
            <div className="text-xs font-semibold uppercase tracking-wider text-brand">New visible posts</div>
            <div className={classNames('text-6xl font-extrabold', !posts && 'text-slate-300')}>
              {abbreviateNumber(posts)}
              {posts >= 10000 && '+'}
            </div>
          </div>

          {inbox > 0 ? (
            <>
              <div className="space-y-1">
                <div className="text-xs font-semibold uppercase tracking-wider text-brand">New posts in Inbox</div>
                <div className="text-2xl font-bold">{abbreviateNumber(inbox)}</div>
              </div>

              <div className="mt-3">
                <CardHelpDetail>
                  <div>
                    {site.ai_moderation_enabled && (!posts ? (<span className="block max-w-[204px]">
                        You can review unpublished posts manually or using <Link className='font-medium' to={`/${site.site_url}/sections?intro=garde`}>Garde&nbsp;AI</Link>.
                      </span>
                      ) : (<span className="block max-w-[296px]">You haven’t published any new posts this week. You can review unpublished posts manually or using <Link className='font-medium' to={`/${site.site_url}/sections?intro=garde`}>Garde&nbsp;AI</Link>.</span>)
                    )}

                    {!site.ai_moderation_enabled && (posts ? (
                      <span className="block max-w-[196px]">
                        Review and publish posts stored in Inbox via the Content tab.
                      </span>
                    ) : (
                      <span className="block max-w-[296px]">
                        You haven’t published any new posts this week. Don’t forget to review and publish posts from
                        Inbox.
                      </span>
                    ))}
                  </div>
                </CardHelpDetail>
              </div>
            </>
          ) : (
            <>
              <div className="mt-3">
                <CardHelpDetail>
                  <div>
                    <span className="block max-w-[212px]">
                      In addition to feeds, you can collect posts manually via the Content tab.
                    </span>
                  </div>
                </CardHelpDetail>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="flex h-full flex-col items-start text-sm">
          <p className="mb-3 leading-tight">
            <strong>There are no new posts collected in the last 7 days.</strong>
          </p>

          <p className="mb-6 leading-tight">
            Did you know that you can pause and change existing automated feeds without losing the content?
          </p>

          <Link className="mt-auto font-bold" to={pathToAutomatedFeeds(site.site_url)}>
            <AngleLink>Create a new feed</AngleLink>
          </Link>
        </div>
      )}
    </Card>
  );
};

export default CardPostStats;
